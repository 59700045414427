<template>
  <div class="image-text">
    <div class="content">
      <ModTit
        :title="
          module.menuJsonObj
            ? module.menuJsonObj[langNamesEnum[languageActive]]
            : module.title
        "
      />
      <div
        class="card"
        :class="'media-' + item.mediaAlign"
        v-for="(item, index) in dataSource.configuration.dataList"
        :key="index"
      >
        <div class="image">
          <img :src="item.media" v-if="item.mediaType == 'image'" />
          <video
            :src="item.media"
            controls
            controlslist="nodownload noplaybackrate noremoteplayback"
            disablePictureInPicture="true"
            v-if="item.mediaType == 'video'"
          />
        </div>
        <div class="text">
          <div class="vhtml" v-html="item.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModTit from "@/components/layout/ModTit.vue";
export default {
  name: "imageText",
  components: {
    ModTit,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="less" scoped>
.image-text {
  .content {
    .mixinWrap();
    .card {
      margin-bottom: 24px;
      .image {
        width: 364px;
        height: 210px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
        video {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        min-height: 210px;
        font-size: 14px;
        line-height: 24px;
        ::v-deep(p) {
          word-break: break-all;
          margin-bottom: 8px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      &.media-left {
        .image {
          float: left;
          margin: 0 12px 0 0;
        }
      }
      &.media-right {
        .image {
          float: right;
          margin: 0 0 0 12px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
